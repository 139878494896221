@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,700&family=Space+Mono:ital,wght@0,400;0,700;1,400&display=swap");

* {
  margin: 0;
  padding: 0;
  font-size: 10px;
  font-family: sans-serif;
}
html {
  background-color: #ffdf6d;
}

main {
  margin: 0 auto;
  max-width: 110rem;
}

.window-div {
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
  padding-right: 2rem;
  overflow: hidden;
}

.window-div .window,
.window-div .window-hidden {
  display: none;
}

.window {
  margin: auto;
  flex-basis: auto;
  position: relative;
  min-width: 100%;
  width: 100%;
  padding: 1rem;
  filter: drop-shadow(0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.447));
}

.window-div .window-visible {
  display: block;
  animation: fadeVisibility 0.5s;
}

@keyframes fadeVisibility {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: opacity linear;
  }
}

.window-div .carousel-controls {
  display: flex;
  justify-content: space-between;
  width: 95%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.window-div .carousel-controls button {
  border-radius: 5rem;
  border: 0;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
}

.window::after {
  position: absolute;
  content: "";
  top: 1rem;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(currentColor 1px, transparent 1px),
    radial-gradient(currentColor 1px, transparent 1px);
  background-size: calc(10 * 1px) calc(10 * 1px);
  background-position: 0 0, calc(12 * 12px) calc(12 * 12px);
  z-index: -1;
}

.menu-bar {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0;
  padding: 0;
}

.menu-center {
  flex: 1 0 auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.left {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spacer-left {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spacer-left .basic-box {
  height: 0.1rem;
  width: 1.8rem;
  filter: blur(0.3px);
}

.bar-container {
  display: flex;
}

.right {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spacer-right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spacer-right .basic-box {
  height: 1.8rem;
  width: 1.8rem;
  filter: blur(0.3px);
}

.spacer-right .basic-box:hover {
  cursor: not-allowed;
}

.basic-box.minimize-btn {
  cursor: not-allowed;
}

.center {
  font-family: "Space Mono", monospace;
  font-size: 1.8rem;
  font-weight: 700;
  text-align: center;
}
.basic-box {
  width: 4.5rem;
  height: 4.5rem;
  border-top: 0.3rem solid #fff;
  border-left: 0.3rem solid #fff;
  border-right: 0.3rem solid #cfcfcf;
  border-bottom: 0.3rem solid #cfcfcf;
  background: #f8f8f8;
}

.bars {
  width: 92%;
  padding-bottom: 0.1rem;
  margin-bottom: 0.2rem;
  height: 0.06rem;
  border-top: 0.2rem solid #dddddd;
  border-left: 0.2rem solid #dddddd;
  border-right: 0.2rem solid #bebebe;
  border-bottom: 0.2rem solid #bebebe;
  background: #f8f8f8;
  justify-content: center;
  filter: blur(0.2px);
}

.content-area {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 63.9rem;
  border: 0.3rem solid #282828;
  border-radius: 0px 0px 0.5rem 0.5rem;
  background-color: #f8f8f8;
  position: relative;
  overflow: hidden;
  margin: 0;
}

.basic-box.spacer-right {
  border: 0.3rem solid #282828;
  border-bottom: none;
  border-radius: 0px 5px 0px 0px;
}

.basic-box.spacer-left {
  border: 0.3rem solid #282828;
  border-bottom: none;
  border-radius: 5px 0px 0px 0px;
}

.basic-box.menu-center {
  border: none;
  border-top: 0.3rem solid #282828;
}

/*   .content-area-background {
    width: 290px;
    height: 290px;
      border-top: .3rem solid #ccc;
      border-left: .3rem solid #ccc;
      border-right: .3rem solid #4f4f50;
      border-bottom: .3rem solid #4f4f50;
      background: #999;
  } */

article {
  font-size: 1.4rem;
}

figure img {
  cursor: pointer;
  max-width: 10%;
  max-height: 10%;
  transform: scale(10.2);
}

section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

section div {
  display: flex;
  flex-direction: column;
  max-width: 25rem;
  align-items: center;
}

figure {
  display: flex;
  justify-content: center;
  align-items: center;
}

figure img {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.header {
  font-family: "Lato", sans-serif;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
}

.name-div {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.name {
  font-family: inherit;
  font-size: 8rem;
  font-weight: 900;
}

.subtitle {
  font-family: inherit;
  font-size: 5rem;
  font-weight: 700;
  margin-bottom: 8rem;
}

.icon {
  width: 50px;
  height: 50px;
  padding-right: 25px;
}

.proj-article {
  font-family: "Space Mono", monospace;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  width: 66%;
}

.proj-name {
  font-family: monospace;
  font-size: 4.5rem;
  font-weight: 700;
  margin-bottom: 1.8rem;
}

.probsol {
  font-family: inherit;
  font-size: 2.4rem;
  font-weight: 700;
  margin-bottom: 1.2rem;
}

.proj-descrip {
  font-size: 1.8rem;
  margin-bottom: 1.4rem;
}

.tech {
  font-style: italic;
}

.link-section {
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
}

.proj-link {
  font-family: inherit;
  color: #000000;
  text-decoration: none;
  white-space: nowrap;
  font-size: 1.8rem;
  font-weight: 700;
  border: 0.2rem solid black;
  border-radius: 1.2rem;
  padding: 1rem;
  margin-bottom: 0.5rem;
}

.modal-window {
  margin: auto;
  flex-basis: auto;
  position: relative;
  min-width: 50%;
  width: 100%;
  filter: drop-shadow(0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.447));
}

.basic-box.close-btn.close-modal {
  cursor: pointer;
}

.modal {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  z-index: 10;
}

.hidden {
  display: none;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  z-index: 5;
}
