@import "https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,700&family=Space+Mono:ital,wght@0,400;0,700;1,400&display=swap";
* {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: 10px;
}

html {
  background-color: #ffdf6d;
}

main {
  max-width: 110rem;
  margin: 0 auto;
}

.window-div {
  justify-content: center;
  margin: 0 auto;
  padding-bottom: 2rem;
  padding-right: 2rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

.window-div .window, .window-div .window-hidden {
  display: none;
}

.window {
  min-width: 100%;
  width: 100%;
  filter: drop-shadow(.2rem .2rem .2rem #00000072);
  flex-basis: auto;
  margin: auto;
  padding: 1rem;
  position: relative;
}

.window-div .window-visible {
  animation: .5s fadeVisibility;
  display: block;
}

@keyframes fadeVisibility {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: opacity linear;
  }
}

.window-div .carousel-controls {
  width: 95%;
  justify-content: space-between;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.window-div .carousel-controls button {
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  border: 0;
  border-radius: 5rem;
}

.window:after {
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: radial-gradient(currentColor 1px, #0000 1px), radial-gradient(currentColor 1px, #0000 1px);
  background-position: 0 0, 144px 144px;
  background-size: 10px 10px;
  position: absolute;
  top: 1rem;
}

.menu-bar {
  justify-content: space-evenly;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
}

.menu-center {
  flex: 1 0 auto;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
}

.left {
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  display: flex;
}

.spacer-left {
  justify-content: center;
  align-items: center;
  display: flex;
}

.spacer-left .basic-box {
  height: .1rem;
  width: 1.8rem;
  filter: blur(.3px);
}

.bar-container {
  display: flex;
}

.right {
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  display: flex;
}

.spacer-right {
  justify-content: center;
  align-items: center;
  display: flex;
}

.spacer-right .basic-box {
  height: 1.8rem;
  width: 1.8rem;
  filter: blur(.3px);
}

.spacer-right .basic-box:hover, .basic-box.minimize-btn {
  cursor: not-allowed;
}

.center {
  text-align: center;
  font-family: Space Mono, monospace;
  font-size: 1.8rem;
  font-weight: 700;
}

.basic-box {
  width: 4.5rem;
  height: 4.5rem;
  background: #f8f8f8;
  border: .3rem solid #cfcfcf;
  border-color: #fff #cfcfcf #cfcfcf #fff;
}

.bars {
  width: 92%;
  height: .06rem;
  filter: blur(.2px);
  background: #f8f8f8;
  border: .2rem solid #bebebe;
  border-color: #ddd #bebebe #bebebe #ddd;
  justify-content: center;
  margin-bottom: .2rem;
  padding-bottom: .1rem;
}

.content-area {
  height: 63.9rem;
  background-color: #f8f8f8;
  border: .3rem solid #282828;
  border-radius: 0 0 .5rem .5rem;
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.basic-box.spacer-right {
  border: .3rem solid #282828;
  border-bottom: none;
  border-radius: 0 5px 0 0;
}

.basic-box.spacer-left {
  border: .3rem solid #282828;
  border-bottom: none;
  border-radius: 5px 0 0;
}

.basic-box.menu-center {
  border: none;
  border-top: .3rem solid #282828;
}

article {
  font-size: 1.4rem;
}

figure img {
  cursor: pointer;
  max-width: 10%;
  max-height: 10%;
  transform: scale(10.2);
}

section {
  flex-direction: column;
  align-items: center;
  display: flex;
}

section div {
  max-width: 25rem;
  flex-direction: column;
  align-items: center;
  display: flex;
}

figure {
  justify-content: center;
  align-items: center;
  display: flex;
}

figure img {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.header {
  flex-direction: column;
  margin-top: 3rem;
  font-family: Lato, sans-serif;
  display: flex;
}

.name-div {
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
  display: flex;
}

.name {
  font-family: inherit;
  font-size: 8rem;
  font-weight: 900;
}

.subtitle {
  margin-bottom: 8rem;
  font-family: inherit;
  font-size: 5rem;
  font-weight: 700;
}

.icon {
  width: 50px;
  height: 50px;
  padding-right: 25px;
}

.proj-article {
  width: 66%;
  flex-direction: column;
  font-family: Space Mono, monospace;
  font-weight: 400;
  display: flex;
}

.proj-name {
  margin-bottom: 1.8rem;
  font-family: monospace;
  font-size: 4.5rem;
  font-weight: 700;
}

.probsol {
  margin-bottom: 1.2rem;
  font-family: inherit;
  font-size: 2.4rem;
  font-weight: 700;
}

.proj-descrip {
  margin-bottom: 1.4rem;
  font-size: 1.8rem;
}

.tech {
  font-style: italic;
}

.link-section {
  flex-direction: row;
  gap: .8rem;
  display: flex;
}

.proj-link {
  color: #000;
  white-space: nowrap;
  border: .2rem solid #000;
  border-radius: 1.2rem;
  margin-bottom: .5rem;
  padding: 1rem;
  font-family: inherit;
  font-size: 1.8rem;
  font-weight: 700;
  text-decoration: none;
}

.modal-window {
  min-width: 50%;
  width: 100%;
  filter: drop-shadow(.2rem .2rem .2rem #00000072);
  flex-basis: auto;
  margin: auto;
  position: relative;
}

.basic-box.close-btn.close-modal {
  cursor: pointer;
}

.modal {
  z-index: 1;
  max-width: 500px;
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hidden {
  display: none;
}

.overlay {
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  z-index: 5;
  background-color: #0009;
  position: absolute;
  top: 0;
  left: 0;
}

/*# sourceMappingURL=index.adeb095c.css.map */
